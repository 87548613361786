import React, { useState } from 'react';

import {
  FlexRow,
  PartlyActiveLink,
  Box,
  LinkPrimaryButton,
  Text,
  LinkBox,
  FlexCol,
} from '../../components';
import { colors, space } from '../../theme';
import styled from 'styled-components';

const StyledLink = styled(PartlyActiveLink)`
  color: ${colors.black};
  &.active {
    color: ${colors.primary};
  }
  &:hover {
    color: ${colors.primary};
  }
`;

const StyledDropdown = styled(Box)`
  position: absolute;
  bottom: 0;
  background: #f9fafc;
  border-radius: 4px;
  transform: translateY(105%);
  padding: ${space.md} ${space.lg};
  z-index: 99;
  width: 200px;

  &:before {
    content: '';
    position: absolute;
    top: -15px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid #f9fafc;
  }

  &:after {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    width: 100%;
    height: 20px;
    background-color: transparent;
  }
`;

const StyledLinkBox = styled(LinkBox)`
  display: flex;
  align-items: center;
  &:hover * {
    color: ${colors.primary};
  }
`;

const DropdownMenuItem: React.FC<{
  menuItem: {
    menuTitle: string;
    menuLink: string;
    isButton: boolean;
    menuItems: {
      menuTitle: string;
      menuLink: string;
      isButton: boolean;
    }[];
  };
}> = ({ menuItem }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <FlexCol
      height="100%"
      justifyContent="center"
      position="relative"
      onMouseOver={() => setMenuOpen(true)}
      onMouseLeave={() => setMenuOpen(false)}
    >
      <StyledLinkBox height="100%">
        <Text>{menuItem.menuTitle}</Text>
      </StyledLinkBox>
      {menuOpen && (
        <StyledDropdown position="absolute" bottom="0">
          {menuItem.menuItems.map((menuItem, index: number) => (
            <Box key={index} py={space.md}>
              <MenuItem title={menuItem.menuTitle} to={menuItem.menuLink} />
            </Box>
          ))}
        </StyledDropdown>
      )}
    </FlexCol>
  );
};

const MenuItem: React.FC<{ title: string; to: string }> = ({ title, to }) => {
  return (
    <StyledLink title={title} to={to}>
      {title}
    </StyledLink>
  );
};

export const Menu: React.FC<{
  menuItems: {
    menuTitle: string;
    menuLink: string;
    isButton: boolean;
    menuItems: {
      menuTitle: string;
      menuLink: string;
      isButton: boolean;
    }[];
  }[];
}> = ({ menuItems }) => {
  return (
    <FlexRow alignItems="center">
      {menuItems &&
        menuItems.map((menuItem: any, index: number) =>
          menuItem.isButton ? (
            <FlexCol
              height="100%"
              justifyContent="center"
              pl={{ md: space.xs2, lg: space.md }}
              pr={
                index + 1 === menuItems.length
                  ? 0
                  : { md: space.xs2, lg: space.md }
              }
            >
              <LinkPrimaryButton
                px={space.md}
                title={menuItem.menuTitle}
                to={menuItem.menuLink}
              >
                {menuItem.menuTitle}
              </LinkPrimaryButton>
            </FlexCol>
          ) : (
            <FlexCol
              height="100%"
              justifyContent="center"
              pl={{ md: space.sm, lg: space.lg }}
              pr={
                index + 1 === menuItems.length
                  ? 0
                  : { md: space.sm, lg: space.lg }
              }
            >
              {menuItem.menuItems && menuItem.menuItems.length > 0 ? (
                <DropdownMenuItem menuItem={menuItem} />
              ) : (
                <MenuItem
                  key={index}
                  title={menuItem.menuTitle}
                  to={menuItem.menuLink}
                />
              )}
            </FlexCol>
          ),
        )}
    </FlexRow>
  );
};
