import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { motion } from 'framer-motion';

import SEO from '../layout/SEO';

import { ThemeProvider } from 'styled-components';

import Header from './Header';

import { useIntl } from '../hooks';
import Footer from './Footer';

import theme from '../theme';
import { Box } from '../components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Layout: React.FC<{
  hideFooter?: boolean;
  hideHeader?: boolean;
  seoTitle: string;
  seoDescription?: string;
  seoImage?: {
    fluid: {
      src: string;
    };
  };
  location: any;
  greyHeader?: boolean;
  header: {
    menuItems: {
      menuTitle: string;
      menuLink: string;
      isButton: boolean;
      menuItems: {
        menuTitle: string;
        menuLink: string;
        isButton: boolean;
      }[];
    }[];
  };
  footer: {
    menuItems: {
      menuTitle: string;
      menuLink: string;
    }[];
    legalMenuItems: {
      menuTitle: string;
      menuLink: string;
    }[];
  };
}> = ({
  children,
  seoTitle,
  seoDescription,
  location,
  seoImage,
  greyHeader,
  hideHeader,
  hideFooter,
  header,
  footer,
}) => {
  const intl = useIntl();
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.5 }}
        >
          <SEO
            lang={intl.locale}
            title={seoTitle}
            description={seoDescription}
            image={
              seoImage && seoImage.fluid
                ? 'https:' + seoImage.fluid.src
                : undefined
            }
          />
          {!hideHeader && (
            <Header
              header={header}
              greyHeader={greyHeader}
              siteTitle={data.site.siteMetadata.title}
            />
          )}

          <main>
            <Box marginTop={{ xs: !hideHeader ? '84px' : '', lg: 0 }}>
              {children}
            </Box>
          </main>
          {!hideFooter && (
            <Footer footer={footer} siteTitle={data.site.siteMetadata.title} />
          )}
        </motion.div>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default Layout;
