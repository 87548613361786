import styled from 'styled-components';
import { SpaceProps, space } from 'styled-system';
import { colors, fontSizes, space as themeSpace } from '../../../theme';

const BaseButton = styled.button`
  border: none;
  font-size: ${fontSizes.body};
  cursor: pointer;
  outline: none;
  border-radius: 2px;
  height: 40px;
  padding: 0 ${themeSpace.xxxl};
  transition: all 0.2s ease-in;
  white-space: nowrap;
  ${space}
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 8px;
  }
` as React.FC<SpaceProps>;

export const PrimaryButton = styled(BaseButton)`
  background-color: ${colors.primary};
  color: ${colors.white};
`;

export const SecondaryButton = styled(BaseButton)`
  background-color: transparent;
  color: ${colors.black};
`;

export const WhiteButton = styled(BaseButton)`
  background-color: ${colors.white};
  color: ${colors.primary};
`;
