import React from 'react';
import styled from 'styled-components';

import { Box, H2, H5, H3 } from '..';
import { colors, fontWeights, space } from '../../theme';

const StyledBoxWithBorder = styled(Box)`
  width: 150px;
  height: 2px;
  background-color: ${(props) => props.color};
`;

export const H2WithBorder: React.FC<{
  title: string;
  textHtml: string;
  textAlign?: 'center' | 'left' | 'right';
  color?: string;
  fontWeight?: number;
}> = ({ title, textHtml, textAlign, color, fontWeight }) => (
  <Box>
    <H2
      color={color || ''}
      textAlign={{ xs: 'left', md: textAlign }}
      fontWeight={fontWeight || fontWeights.extraBold}
    >
      {title}
    </H2>
    <Box pt={space.lg} pb={space.xl}>
      <StyledBoxWithBorder
        color={color || colors.primary}
        marginLeft={{ xs: '', md: textAlign === 'center' ? 'auto' : '' }}
        marginRight={{ xs: '', md: textAlign === 'center' ? 'auto' : '' }}
      />
    </Box>
    <H5 color={color || ''} textAlign={textAlign}>
      <div
        dangerouslySetInnerHTML={{
          __html: textHtml,
        }}
      />
    </H5>
  </Box>
);

export const H3WithBorder: React.FC<{
  title: string;
  textHtml: string;
  textAlign?: 'center' | 'left' | 'right';
  color?: string;
  fontWeight?: number;
}> = ({ title, textHtml, textAlign = 'left', color, fontWeight }) => (
  <Box>
    <H3
      color={color || ''}
      textAlign={{ xs: 'left', md: textAlign }}
      fontWeight={fontWeight || fontWeights.extraBold}
    >
      {title}
    </H3>
    <Box pt={space.md} pb={space.xl}>
      <StyledBoxWithBorder
        color={color || colors.primary}
        marginLeft={{ xs: '', md: textAlign === 'center' ? 'auto' : '' }}
        marginRight={{ xs: '', md: textAlign === 'center' ? 'auto' : '' }}
      />
    </Box>
    <H5 color={color || ''} textAlign={textAlign}>
      <div
        dangerouslySetInnerHTML={{
          __html: textHtml,
        }}
      />
    </H5>
  </Box>
);
