import React from 'react';
import styled from 'styled-components';

import logo from '../../../images/logo.svg';
import mobileLogo from '../../../images/mobile-logo.svg';
import whiteLogo from '../../../images/logo-white.svg';

const StyledCinchBuildLogo = styled.img`
  height: 32px;
`;

export const CinchBuildLogo: React.FC<{ alt: string }> = ({ alt }) => (
  <StyledCinchBuildLogo src={logo} alt={alt} />
);

export const CinchBuildWhiteLogo: React.FC<{ alt: string }> = ({ alt }) => (
  <StyledCinchBuildLogo src={whiteLogo} alt={alt} />
);

export const CinchBuildMobileLogo: React.FC<{ alt: string }> = ({ alt }) => (
  <StyledCinchBuildLogo src={mobileLogo} alt={alt} />
);
