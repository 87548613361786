import React, { useState } from 'react';
import {
  Link,
  Grid,
  CinchBuildLogo,
  CinchBuildMobileLogo,
  FlexRow,
  Separator,
  Section,
  Hidden,
  Visible,
  LinkBox,
  Box,
  FlexCol,
} from '../../components';
import { colors, space } from '../../theme';
import { Menu } from './Menu';
import { MobileMenu } from './MobileMenu';
import styled from 'styled-components';

import { motion, AnimatePresence } from 'framer-motion';

const StyledBurger = styled(Box)`
  position: absolute;
  top: 10px;
  left: 0;
  width: 20px;
  height: 2px;
  display: block;
  background: ${colors.primary};
  transition: background 0.2s ease-in-out;
  visibility: ${(props) => (props.mobileMenuOpen ? 'hidden' : '')};
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 2px;
    background: inherit;
    transition: transform 0.2s ease-in-out;
  }
  &:before {
    visibility: ${(props) => (props.mobileMenuOpen ? 'visible' : '')};
    transform: ${(props) =>
      props.mobileMenuOpen
        ? 'translateY(0) rotate(45deg)'
        : 'translateY(-6px)'};
  }
  &:after {
    visibility: ${(props) => (props.mobileMenuOpen ? 'visible' : '')};
    transform: ${(props) =>
      props.mobileMenuOpen
        ? 'translateY(0) rotate(-45deg)'
        : 'translateY(6px)'};
  }
`;

const StyledMobileMenuWrapper = styled(Box)`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  background-color: ${colors.white};
`;

const Header: React.FC<{
  siteTitle: string;
  greyHeader?: boolean;
  header: {
    menuItems: {
      menuTitle: string;
      menuLink: string;
      isButton: boolean;
      menuItems: {
        menuTitle: string;
        menuLink: string;
        isButton: boolean;
      }[];
    }[];
  };
}> = ({ siteTitle = '', greyHeader = false, header }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const variants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: '-110%' },
  };

  const buttonMenuItems = header.menuItems.filter(
    (menuItem) => menuItem.isButton,
  );

  return (
    <header>
      <Hidden xs sm md>
        <Section backgroundColor={greyHeader ? colors.secondaryDark005 : ''}>
          <Grid.Container>
            <FlexRow justifyContent="space-between" py={space.xl}>
              <Link to="/" title={siteTitle}>
                <CinchBuildLogo alt={siteTitle} />
              </Link>
              <Menu menuItems={header.menuItems} />
            </FlexRow>
            <Separator.Light />
          </Grid.Container>
        </Section>
      </Hidden>
      <Visible xs sm md>
        <StyledMobileMenuWrapper>
          <FlexRow
            style={{ zIndex: 9999 }}
            py={space.lg}
            px={space.xl}
            justifyContent="space-between"
            alignItems="center"
          >
            <FlexCol flexBasis="33%" style={{ position: 'relative' }}>
              <LinkBox
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                style={{ height: 20, width: 20 }}
              >
                <StyledBurger mobileMenuOpen={mobileMenuOpen} />
              </LinkBox>
            </FlexCol>
            <FlexCol flexBasis="33%" alignItems="center">
              <Link to="/" title={siteTitle}>
                <CinchBuildMobileLogo alt={siteTitle} />
              </Link>
            </FlexCol>
            <FlexCol flexBasis="33%" alignItems="flex-end">
              {buttonMenuItems.map((menuItem: any, index: number) => (
                <Link
                  title={menuItem.menuTitle}
                  to={menuItem.menuLink}
                  key={index}
                >
                  {menuItem.menuTitle}
                </Link>
              ))}
            </FlexCol>
          </FlexRow>
          <Separator.Light />
        </StyledMobileMenuWrapper>
        <AnimatePresence>
          <motion.div
            animate={mobileMenuOpen ? 'open' : 'closed'}
            transition={{ duration: 0.5 }}
            variants={variants}
            initial={false}
            style={{
              position: 'fixed',
              backgroundColor: '#fff',
              width: '100%',
              zIndex: 999,
              height: 'calc(100vh - 84px)',
            }}
          >
            <MobileMenu menuItems={header.menuItems} />
          </motion.div>
        </AnimatePresence>
      </Visible>
    </header>
  );
};

export default Header;
