import React from 'react';
import { Link as GLink } from 'gatsby-plugin-intl';
import { Text, PrimaryButton, WhiteButton } from '../../.';
import { colors } from '../../../theme';
import { SpaceProps } from 'styled-system';

const getTo = (to) => {
  const stringLength = to ? to.length : 0;
  if (to && to[0] !== '#' && to[stringLength - 1] !== '/') {
    return `${to}/`;
  }
  return to;
};

export const BaseLink: React.FC<any> = (props) => {
  if (props.to && props.to.substring(0, 4) === 'http') {
    return (
      <a {...props} target="_blank" title={props.title} href={props.to}>
        {props.children}
      </a>
    );
  }
  return <GLink {...props} to={getTo(props.to)} />;
};

const isPartiallyActive = (className) => ({ isPartiallyCurrent }) => ({
  className: className + (isPartiallyCurrent ? ` active` : ``),
});

export const PartlyActiveLink = ({ className, ...rest }) => (
  <BaseLink to={rest.to} getProps={isPartiallyActive(className)} {...rest} />
);

export const Link: React.FC<{
  to: string;
  title: string;
  activeStyle?: any;
  color?: string;
}> = ({ to, children, title, activeStyle, color }) => (
  <BaseLink to={to} title={title} activeStyle={activeStyle}>
    <Text color={color || colors.primary}>{children}</Text>
  </BaseLink>
);

export const LinkPrimaryButton: React.FC<
  { to: string; title: string } & SpaceProps
> = ({ to, children, title, ...props }) => (
  <BaseLink to={to} title={title}>
    <PrimaryButton {...props}>{children}</PrimaryButton>
  </BaseLink>
);

export const LinkWhiteButton: React.FC<
  { to: string; title: string } & SpaceProps
> = ({ to, children, title, ...props }) => (
  <BaseLink to={to} title={title}>
    <WhiteButton {...props}>{children}</WhiteButton>
  </BaseLink>
);
