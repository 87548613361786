import React, { useState } from 'react';

import {
  PartlyActiveLink,
  Box,
  LinkBox,
  FlexCol,
  Text,
  FlexRow,
  Icons,
} from '../../components';
import { colors, space } from '../../theme';
import styled from 'styled-components';

const StyledLink = styled(PartlyActiveLink)`
  color: ${(props) => props.color};
  &.active {
    color: ${colors.primary};
  }
  &:hover {
    color: ${colors.primary};
  }
`;

const MenuItem: React.FC<{ title: string; to: string; color?: string }> = ({
  title,
  to,
  color,
}) => {
  return (
    <StyledLink title={title} to={to} color={color || colors.blac}>
      {title}
    </StyledLink>
  );
};

const StyledMenu = styled(Box)``;

const StyledLinkBox = styled(LinkBox)`
  &:hover * {
    color: ${colors.primary};
  }
`;

const DropdownMenuItem: React.FC<{
  menuItem: {
    menuTitle: string;
    menuLink: string;
    isButton: boolean;
    menuItems: {
      menuTitle: string;
      menuLink: string;
      isButton: boolean;
    }[];
  };
}> = ({ menuItem }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <FlexCol height="100%" justifyContent="center" position="relative">
      <StyledLinkBox onClick={() => setMenuOpen(!menuOpen)} height="100%">
        <FlexRow alignItems="center" justifyContent="space-between">
          <Text>{menuItem.menuTitle}</Text>
          <Box>
            {menuOpen ? (
              <Icons.ChevronDown size="18" />
            ) : (
              <Icons.ChevronRight size="18" />
            )}
          </Box>
        </FlexRow>
      </StyledLinkBox>
      {menuOpen && (
        <Box mt={space.sm}>
          {menuItem.menuItems.map((item, index: number) => (
            <Box
              key={index}
              pt={space.sm}
              pb={index + 1 === menuItem.menuItems.length ? 0 : space.sm}
            >
              <MenuItem
                color={colors.grey}
                title={item.menuTitle}
                to={item.menuLink}
              />
            </Box>
          ))}
        </Box>
      )}
    </FlexCol>
  );
};

export const MobileMenu: React.FC<{
  menuItems: {
    menuTitle: string;
    menuLink: string;
    isButton: boolean;
    menuItems: {
      menuTitle: string;
      menuLink: string;
      isButton: boolean;
    }[];
  }[];
}> = ({ menuItems }) => {
  return (
    <StyledMenu py={space.lg} px={space.xxl}>
      {menuItems &&
        menuItems.map((menuItem: any, index: number) =>
          menuItem.isButton ? null : (
            <Box
              pl={{ md: space.sm, lg: space.lg }}
              pr={
                index + 1 === menuItems.length
                  ? 0
                  : { md: space.sm, lg: space.lg }
              }
            >
              <Box py={space.md}>
                {menuItem.menuItems && menuItem.menuItems.length > 0 ? (
                  <DropdownMenuItem menuItem={menuItem} />
                ) : (
                  <MenuItem
                    key={index}
                    title={menuItem.menuTitle}
                    to={menuItem.menuLink}
                  />
                )}
              </Box>
            </Box>
          ),
        )}
    </StyledMenu>
  );
};
