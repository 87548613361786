import React from 'react';
import styled from 'styled-components';
import {
  Box,
  FlexCol,
  Text,
  Link,
  H4,
  LinkPrimaryButton,
  FlexRow,
  Icons,
  Hidden,
  Visible,
} from '../../../components';
import { colors, fontWeights, space } from '../../../theme';

const BOX_SHADOW = '0px 8px 20px rgba(168, 168, 168, 0.25)';

const StyledBox = styled(FlexCol)`
  background: ${colors.white};
  box-shadow: ${BOX_SHADOW};
  border-radius: 4px;
  padding: ${space.lg2};
  flex: 1;
  justify-content: center;
  text-align: ${(props) => props.textAlign};
`;

const STYLED_SQUARE_BOX_SIZE = '40px';
const STYLED_SQUARE_BOX_DEFAULT_COLOR =
  'linear-gradient(180deg, #5FB3DF -50.98%, rgba(95, 179, 223, 0) 176.47%)';

const StyledSquareBox = styled(FlexCol)`
  width: ${STYLED_SQUARE_BOX_SIZE};
  height: ${STYLED_SQUARE_BOX_SIZE};
  border-radius: 3px;
  background: ${(props) => props.background};
  align-items: center;
  justify-content: center;
`;

export const TextBoxWithLinkAndSquareColor: React.FC<{
  title: string;
  text: string;
  linkTitle: string;
  linkUrl: string;
  squareBoxColor: string;
}> = ({
  title,
  text,
  linkTitle,
  linkUrl,
  squareBoxColor = STYLED_SQUARE_BOX_DEFAULT_COLOR,
}) => {
  return (
    <Link title={linkTitle} to={linkUrl}>
      <Hidden xs sm>
        <StyledBox>
          <FlexRow>
            <FlexCol mr={space.lg} flexShrink={0}>
              <StyledSquareBox background={squareBoxColor} />
            </FlexCol>
            <Box>
              <Box marginBottom={space.md}>
                <H4 fontWeight={fontWeights.semiBold}>{title}</H4>
              </Box>
              <Box marginBottom={space.md}>
                <Text color={colors.darkestGrey}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  />
                </Text>
              </Box>
              <Box>
                <Text color={colors.primary}>{linkTitle}</Text>
              </Box>
            </Box>
          </FlexRow>
        </StyledBox>
      </Hidden>

      <Visible xs sm>
        <StyledBox>
          <FlexRow alignItems="center">
            <FlexCol mr={space.lg} flexShrink={0}>
              <StyledSquareBox background={squareBoxColor} />
            </FlexCol>
            <Box>
              <Box>
                <H4 fontWeight={fontWeights.semiBold}>{title}</H4>
              </Box>
            </Box>
          </FlexRow>
          <Box marginTop={space.md}>
            <Text color={colors.darkestGrey}>{text}</Text>
          </Box>
          <Box marginTop={space.md}>
            <Text color={colors.primary}>{linkTitle}</Text>
          </Box>
        </StyledBox>
      </Visible>
    </Link>
  );
};

export const TextBoxWithIcon: React.FC<{
  title: string;
  text: string;
  icon: string;
}> = ({ title, text, icon }) => {
  const IconComponent = Icons[icon];
  if (!IconComponent) return null;
  return (
    <>
      <Hidden xs sm>
        <StyledBox>
          <FlexRow>
            <FlexCol mr={space.lg} flexShrink={0}>
              <StyledSquareBox background={colors.primary}>
                <IconComponent color="white" />
              </StyledSquareBox>
            </FlexCol>
            <Box>
              <Box marginBottom={space.md}>
                <H4 fontWeight={fontWeights.semiBold}>{title}</H4>
              </Box>
              <Box marginBottom={space.md}>
                <Text color={colors.darkestGrey}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  />
                </Text>
              </Box>
            </Box>
          </FlexRow>
        </StyledBox>
      </Hidden>
      <Visible xs sm>
        <StyledBox>
          <FlexRow alignItems="center">
            <FlexCol mr={space.lg} flexShrink={0}>
              <StyledSquareBox background={colors.primary}>
                <IconComponent color="white" />
              </StyledSquareBox>
            </FlexCol>
            <Box>
              <Box>
                <H4 fontWeight={fontWeights.semiBold}>{title}</H4>
              </Box>
            </Box>
          </FlexRow>
          <Box marginTop={space.md}>
            <Text color={colors.darkestGrey}>
              <div
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            </Text>
          </Box>
        </StyledBox>
      </Visible>
    </>
  );
};

export const TextBoxWithButton: React.FC<{
  linkBoxTitle: string;
  linkBoxButtonTitle: string;
  linkBoxButtonUrl: string;
}> = ({ linkBoxTitle, linkBoxButtonTitle, linkBoxButtonUrl }) => {
  return (
    <StyledBox textAlign="center">
      <Box margin="0 auto" marginBottom={space.xl} maxWidth={320}>
        <H4 fontWeight={fontWeights.semiBold}>{linkBoxTitle}</H4>
      </Box>
      <Box>
        <LinkPrimaryButton title={linkBoxButtonTitle} to={linkBoxButtonUrl}>
          {linkBoxButtonTitle}
        </LinkPrimaryButton>
      </Box>
    </StyledBox>
  );
};
