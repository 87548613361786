import styled from 'styled-components';
import { colors, fontSizes, fonts } from '../../../theme';

export const Input = styled.input`
  -webkit-appearance: none;
  border: none;
  background: transparent;
  border-bottom: 1px solid
    ${(props) => (props.error ? colors.primaryDark : colors.white)};
  font-size: ${fontSizes.body};
  color: ${colors.white};
  width: 100%;
  display: block;
  font-family: ${fonts.averta};
  text-align: center;
  height: 35px;
  line-height: 35px;
  outline: none;
  &::-webkit-input-placeholder {
    font-family: ${fonts.averta};
    color: ${colors.white};
  }
`;
