import React from 'react';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl';
import { Box } from '../components';
import { space } from '../theme';

const languageName = {
  en: 'English',
  de: 'Deutsch',
};

const LanguageSwitcher = () => (
  <IntlContextConsumer>
    {({
      languages,
      language: currentLocale,
    }: {
      languages: TLanguage[];
      language: TLanguage;
    }) =>
      languages.map((language: TLanguage) => (
        <Box display="inline-block" px={space.sm} key={language}>
          <a
            onClick={() => {
              changeLocale(language);
            }}
            style={{
              color: 'white',
              textDecoration: currentLocale === language ? `underline` : ``,
              cursor: 'pointer',
            }}
          >
            {languageName[language]}
          </a>
        </Box>
      ))
    }
  </IntlContextConsumer>
);

export default LanguageSwitcher;
