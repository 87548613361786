import React from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { colors } from '../../../theme';

const Light = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
  ${space};
` as React.FC<SpaceProps>;

const Secondary = styled.div`
  border-bottom: 2px solid ${colors.secondary02};
  ${space};
` as React.FC<SpaceProps>;

export const Separator = {
  Light,
  Secondary,
};
