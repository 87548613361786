import React from 'react';
import Img from 'gatsby-image';
import { Box } from '..';

export const Image: React.FC<{ image: any }> = ({ image }) => {
  if (image && image.file && !image.fluid) {
    return (
      <Box style={{ maxHeight: '100%', maxWidth: '100%' }}>
        <img
          style={{ objectFit: 'contain' }}
          alt={image && image.alt}
          src={image && image.file.url}
        />
      </Box>
    );
  }
  return (
    <Img
      style={{ maxHeight: '100%', maxWidth: '100%' }}
      alt={image && image.alt}
      fluid={image && image.fluid}
      imgStyle={{ objectFit: 'contain' }}
    />
  );
};
