import { Theme } from 'styled-system';

export const breakpoints: Record<string, string> = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};

export const space: Record<string, string> = {
  xxs: '0px',
  xs: '2px',
  xs2: '4px',
  sm: '8px',
  md: '16px',
  lg: '24px',
  lg2: '28px',
  xl: '32px',
  xl2: '36px',
  xxl: '40px',
  xxxl: '48px',
  xxxxl: '56px',
  '5xl': '64px',
  '6xl': '72px',
  '7xl': '80px',
  '8xl': '88px',
};

export const fontSizes: Record<string, string> = {
  h1: '48px',
  h2: '38px',
  h3: '28px',
  h4: '20px',
  h5: '18px',
  body: '16px',
  tiny: '15px',
};

export const fontWeights: Record<string, number> = {
  regular: 400,
  bold: 500,
  semiBold: 600,
  extraBold: 800,
};

export const lineHeights: Record<string, string> = {
  h1: '62px',
  h2: '49px',
  h3: '35px',
  h4: '25px',
  h5: '27px',
  body: '20px',
  tiny: '12px',
};

export const fonts: Record<string, string> = {
  averta: 'Averta',
};

export const colors: Record<string, string> = {
  black: '#1B1F24',
  white: '#fff',
  primary: '#FF6240',
  primaryDark: '#D33917',
  secondary: '#B6D1F0',
  secondary02: '#b6d1f033',
  secondaryDark: '#93A9C2',
  secondaryDark005: '#93a9c20d',
  grey: '#A1A4AB',
  lightGrey: '#C9CBD0',
  lightestGrey: '#E9EAEC',
  darkGrey: '#637182',
  darkestGrey: '#323A42',
  darkestGrey05: '#323a4280',
  transparent: 'transparent',
};

export const iconSizes = {
  xs: 12,
  sm: 24,
  md: 36,
  lg: 48,
};

const theme: Theme = {
  colors: colors,
  space: space,
  breakpoints: breakpoints,
  fontSizes: fontSizes,
  fontWeights: fontWeights,
  fonts: fonts,
  lineHeights: lineHeights,
};

export default theme;
