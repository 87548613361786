import React from 'react';
import styled from 'styled-components';
import { typography, color, TypographyProps, ColorProps } from 'styled-system';

import { fonts, fontSizes, colors, lineHeights } from '../../../theme';

export const Text = styled.p`
  font-family: ${fonts.averta};
  font-size: ${fontSizes.body};
  line-height: ${lineHeights.body};
  color: ${colors.darkestGrey};
  ${typography};
  ${color};
` as React.FC<TypographyProps & ColorProps>;

export const H1 = styled.h1`
  font-family: ${fonts.averta};
  font-size: ${fontSizes.h1};
  line-height: ${lineHeights.h1};
  color: ${colors.black};
  ${typography};
  ${color};
` as React.FC<TypographyProps & ColorProps>;

export const H2 = styled.h2`
  font-family: ${fonts.averta};
  font-size: ${fontSizes.h2};
  line-height: ${lineHeights.h2};
  color: ${colors.black};
  ${typography};
  ${color};
` as React.FC<TypographyProps & ColorProps>;

export const H3 = styled.h3`
  font-family: ${fonts.averta};
  font-size: ${fontSizes.h3};
  line-height: ${lineHeights.h3};
  color: ${colors.black};
  ${typography};
  ${color};
` as React.FC<TypographyProps & ColorProps>;

export const H4 = styled.h4`
  font-family: ${fonts.averta};
  font-size: ${fontSizes.h4};
  line-height: ${lineHeights.h4};
  color: ${colors.black};
  ${typography};
  ${color};
` as React.FC<TypographyProps & ColorProps>;

export const H5 = styled.h5`
  font-family: ${fonts.averta};
  font-size: ${fontSizes.h5};
  line-height: ${lineHeights.h5};
  color: ${colors.black};
  ${typography};
  ${color};
` as React.FC<TypographyProps & ColorProps>;
