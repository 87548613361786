import React from 'react';
import styled from 'styled-components';
import {
  space,
  flexbox,
  layout,
  position,
  FlexboxProps,
  SpaceProps,
  LayoutProps,
  PositionProps,
  ColorProps,
  color,
} from 'styled-system';

export const Box = styled.div`
  ${space}
  ${layout}
  ${position}
  ${color}
  ` as React.FC<
  SpaceProps &
    LayoutProps &
    PositionProps &
    ColorProps &
    React.HTMLAttributes<HTMLDivElement>
>;

export const LinkBox = styled(Box)`
  cursor: pointer;
  ${space}
  ${layout}
  ${position}
  ${color}
  ` as React.FC<
  SpaceProps &
    LayoutProps &
    PositionProps &
    ColorProps &
    React.HTMLAttributes<HTMLDivElement>
>;

export const FlexRow = styled.div`
  ${flexbox}
  ${space}
  ${layout}
  ${position}
  ${color}
  display: flex;
  flex-direction: row;
` as React.FC<
  FlexboxProps &
    SpaceProps &
    LayoutProps &
    PositionProps &
    ColorProps &
    React.HTMLAttributes<HTMLDivElement>
>;

export const FlexCol = styled.div`
  ${flexbox}
  ${space}
  ${layout}
  ${position}
  ${color}
  display: flex;
  flex-direction: column;
` as React.FC<
  FlexboxProps &
    SpaceProps &
    LayoutProps &
    PositionProps &
    ColorProps &
    React.HTMLAttributes<HTMLDivElement>
>;
