import React from 'react';

import { colors } from '../../../theme';

import Cubes from '../../../icons/Cubes.svg';
import Mind from '../../../icons/Mind.svg';
import Reports from '../../../icons/Reports.svg';
import World from '../../../icons/World.svg';
import Check from '../../../icons/Check.svg';
import Eye from '../../../icons/Eye.svg';
import Share from '../../../icons/Share.svg';
import Key from '../../../icons/Key.svg';
import PenTool from '../../../icons/PenTool.svg';
import Users from '../../../icons/Users.svg';
import GitPullRequest from '../../../icons/GitPullRequest.svg';
import Refresh from '../../../icons/Refresh.svg';
import CPU from '../../../icons/CPU.svg';
import Money from '../../../icons/Money.svg';
import Cloud from '../../../icons/Cloud.svg';
import Shield from '../../../icons/Shield.svg';
import UserStats from '../../../icons/UserStats.svg';
import Brush from '../../../icons/Brush.svg';
import Company from '../../../icons/Company.svg';
import Compass from '../../../icons/Compass.svg';
import Medal from '../../../icons/Medal.svg';
import Database from '../../../icons/Database.svg';
import ChevronDown from '../../../icons/ChevronDown.svg';
import DollarSign from '../../../icons/DollarSign.svg';
import CrossHair from '../../../icons/CrossHair.svg';
import Calendar from '../../../icons/Calendar.svg';
import Layers from '../../../icons/Layers.svg';
import Tool from '../../../icons/Tool.svg';
import UploadCloud from '../../../icons/UploadCloud.svg';
import Stash from '../../../icons/Stash.svg';
import CheckCircle from '../../../icons/CheckCircle.svg';

type IconSize = '12' | '18' | '24' | '36' | '48';
type IconColor = 'darkestGrey' | 'white';
type IconStyle = 'stroke' | 'fill';

export type IconProps = {
  color?: IconColor;
  className?: string;
  size?: IconSize;
  onClick?: () => void;
};

const createIcon = ({
  Component,
  transform,
  iconStyle,
}: {
  Component: any;
  transform?: any;
  iconStyle: IconStyle;
}) => ({ color, ...props }: IconProps) => {
  return (
    <Component
      style={{ transform }}
      width={props.size || 24}
      height={props.size || 24}
      className={`${iconStyle === 'stroke' ? 'stroke' : 'fill'} ${color}`}
    />
  );
};

const Icons = {
  Cubes: createIcon({ Component: Cubes, iconStyle: 'fill' }),
  Mind: createIcon({ Component: Mind, iconStyle: 'fill' }),
  Reports: createIcon({ Component: Reports, iconStyle: 'fill' }),
  World: createIcon({ Component: World, iconStyle: 'fill' }),
  Share: createIcon({ Component: Share, iconStyle: 'stroke' }),
  Eye: createIcon({ Component: Eye, iconStyle: 'stroke' }),
  Check: createIcon({ Component: Check, iconStyle: 'stroke' }),
  Key: createIcon({ Component: Key, iconStyle: 'stroke' }),
  PenTool: createIcon({ Component: PenTool, iconStyle: 'stroke' }),
  Users: createIcon({ Component: Users, iconStyle: 'stroke' }),
  Refresh: createIcon({ Component: Refresh, iconStyle: 'stroke' }),
  GitPullRequest: createIcon({
    Component: GitPullRequest,
    iconStyle: 'stroke',
  }),
  CPU: createIcon({ Component: CPU, iconStyle: 'stroke' }),
  Money: createIcon({ Component: Money, iconStyle: 'fill' }),
  Cloud: createIcon({ Component: Cloud, iconStyle: 'fill' }),
  Shield: createIcon({ Component: Shield, iconStyle: 'fill' }),
  UserStats: createIcon({ Component: UserStats, iconStyle: 'fill' }),
  Brush: createIcon({ Component: Brush, iconStyle: 'fill' }),
  Company: createIcon({ Component: Company, iconStyle: 'fill' }),
  Compass: createIcon({ Component: Compass, iconStyle: 'fill' }),
  Medal: createIcon({ Component: Medal, iconStyle: 'fill' }),
  Database: createIcon({ Component: Database, iconStyle: 'stroke' }),
  ChevronDown: createIcon({ Component: ChevronDown, iconStyle: 'fill' }),
  ChevronTop: createIcon({
    Component: ChevronDown,
    transform: 'rotate(180deg)',
    iconStyle: 'fill',
  }),
  ChevronRight: createIcon({
    Component: ChevronDown,
    transform: 'rotate(270deg)',
    iconStyle: 'fill',
  }),
  CrossHair: createIcon({
    Component: CrossHair,
    iconStyle: 'stroke',
  }),
  Calendar: createIcon({
    Component: Calendar,
    iconStyle: 'stroke',
  }),
  DollarSign: createIcon({
    Component: DollarSign,
    iconStyle: 'stroke',
  }),
  Layers: createIcon({
    Component: Layers,
    iconStyle: 'stroke',
  }),
  UploadCloud: createIcon({
    Component: UploadCloud,
    iconStyle: 'stroke',
  }),
  Tool: createIcon({
    Component: Tool,
    iconStyle: 'stroke',
  }),
  Stash: createIcon({
    Component: Stash,
    iconStyle: 'stroke',
  }),
  CheckCircle: createIcon({
    Component: CheckCircle,
    iconStyle: 'stroke',
  }),
};

export { Icons };
