import React from 'react';
import styled from 'styled-components';

import LanguageSwitcher from '../LanguageSwitcher';
import {
  Grid,
  Section,
  CinchBuildWhiteLogo,
  Box,
  PartlyActiveLink,
  FlexCol,
  Text,
  FlexRow,
  Link,
} from '../../components';
import { colors, space } from '../../theme';
import { useIntl } from '../../hooks';

const StyledFooter = styled.footer`
  background-color: #637182;
`;

const StyledLink = styled(PartlyActiveLink)`
  color: ${colors.white};
  &.active {
    text-decoration: underline;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const MenuItem: React.FC<{ title: string; to: string }> = ({ title, to }) => (
  <FlexCol py={space.sm} alignItems={{ xs: 'center', md: 'flex-start' }}>
    <Text color={colors.white}>
      <StyledLink title={title} to={to}>
        {title}
      </StyledLink>
    </Text>
  </FlexCol>
);

const Footer: React.FC<{
  siteTitle: string;
  footer: {
    menuItems: {
      menuTitle: string;
      menuLink: string;
    }[];
    legalMenuItems: {
      menuTitle: string;
      menuLink: string;
    }[];
  };
}> = ({ siteTitle, footer }) => {
  const intl = useIntl();

  return (
    <StyledFooter>
      <Grid.Container>
        <Section py={space.xxl}>
          <Grid.Row>
            <Grid.Col lg={4} xl={3}>
              <FlexCol mb={{ xs: space.xxl, md: '' }}>
                <Box margin={{ xs: '0 auto', md: '0' }}>
                  <CinchBuildWhiteLogo alt={siteTitle} />
                </Box>
              </FlexCol>
            </Grid.Col>
            <Grid.Col lg={4} xl={3}>
              <FlexCol mb={{ xs: space.xl, md: '' }}>
                {footer.menuItems.map((menuItem, index: number) => (
                  <MenuItem
                    title={menuItem.menuTitle}
                    to={menuItem.menuLink}
                    key={index}
                  />
                ))}
              </FlexCol>
            </Grid.Col>
            <Grid.Col lg={1} xl={3}></Grid.Col>
            <Grid.Col lg={3} xl={3} style={{ textAlign: 'right' }}>
              <LanguageSwitcher />
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <FlexCol mt={space.xl}>
                <FlexRow>
                  {footer.legalMenuItems.map((menuItem, index: number) => (
                    <Box
                      ml={index !== 0 ? space.lg : 0}
                      mr={space.lg}
                      key={index}
                    >
                      <MenuItem
                        title={menuItem.menuTitle}
                        to={menuItem.menuLink}
                      />
                    </Box>
                  ))}
                </FlexRow>
              </FlexCol>
            </Grid.Col>
          </Grid.Row>
        </Section>
      </Grid.Container>
    </StyledFooter>
  );
};

export default Footer;
